<template>
  <div>
  	   <div class="auth-wrapper w-50 mx-auto px-2 mt-4">
      <div class="auth-inner py-2" style="max-width: 812px;">
    <form-wizard
      ref="active"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class=" mb-3"
      @on-complete="updateInfo"
    >
     <tab-content
        title="Verify Email"
        :before-change="validationForm"
      >
        <validation-observer
          ref="emailRule"
          tag="form"
        >
       <b-row>
            <b-col md="6">
              <b-form-group
                label-for="new_email_id"
                label="Email"
              >
                <validation-provider
                  ref="emailprovider"
                  #default="{ errors }"
                  name="Email Id"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="email_id"
                      v-model="data.email"
                      disabled
                      name="email"
                      placeholder="Enter Email Id"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="MailIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-for="otp"
                label="OTP"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:6|validOtp"
                  name="OTP"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="otp"
                      v-model="otp"
                      type="number"
                      name="otp"
                      placeholder="Enter OTP"
                    />

                  </b-input-group>
                  <small
                    v-if="errors[0]!='OTP is not valid.'"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                  <small
                    v-if="validMessge !='' && errors[0]=='OTP is not valid.'"
                    class="text-danger"
                  >{{ validMessge }}</small>
                  <small
                    v-if="validtrueMessge !=''"
                    class="text-success"
                  >{{ validtrueMessge }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6 mt-2">
              <b-button
                v-if="stopTrying"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="loading"
                variant="outline-success"
                class=" mr-1"
                @click="requetOTP"
              >
                <b-spinner
                  v-show="loading"
                  small
                />
                Request OTP
              </b-button>
              <countdown
                v-if="!stopTrying && showTimer"
                :time="waitingTime"
                class="mr-1"
                @end="handleCountdownEnd"
              >
                <template slot-scope="props">
                  {{ props.minutes }}:{{ props.seconds }}
                </template>
              </countdown>
            </b-col>
          </b-row>

          </validation-observer>  
    </tab-content>
      <tab-content
        title="Address"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Address
              </h5>
              <small class="text-muted">Enter Your Address.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Address1"
                label-for="address1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address1"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="data.address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter Address Lin 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Address2"
                label-for="address2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address2"
                >
                  <b-form-input
                    id="address"
                    v-model="data.address2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter Address Lin 2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Postal Code"
                label-for="postcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postal Code"
                  rules="required"
                >
                  <b-form-input
                    id="postcode"
                    v-model="data.postcode"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    placeholder="Enter Post Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="City"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    id="city"
                    v-model="data.city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter City Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  label="Country"
                  label-for="country"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="country"
                    placeholder="Select Country"
                    v-model="data.country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryOptions"
                    :reduce="val => val.value"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="State"
                rules="required"
              >
                <b-form-group
                  label="State"
                  label-for="state"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="State"
                     v-model="data.state"
                    placeholder="Select State"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="stateOptions"
                    :reduce="val => val.value"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- other Info -->
      <tab-content
        title="Other Info"
        :before-change="validationOtherInfo"
      >
        <validation-observer
          ref="otherInfoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Other Info
              </h5>
              <small class="text-muted">Enter Your Other Info</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of birth"
                label-for="dob"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="data.date_of_birth"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit'}"
                  locale="en"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="gender"
                rules="required"
              >
                <b-form-group
                  label="Gender"
                  label-for="gender"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="gender"
                    v-model="data.gender"
                    placeholder="Select gander"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOptions"
                    :reduce="val => val.value"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nationality"
                label-for="Nationality"
              >
                <b-form-input
                  id="Nationality"
                  v-model="data.nationality"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Religion"
                rules="required"
              >
                <b-form-group
                  label="Religion"
                  label-for="Religion"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="Religion"
                     v-model="data.religion"
                     placeholder="Select Religion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="religionOptions"
                    :reduce="val => val.value"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
             <b-col md="6">
              <b-form-group
                label=" Account Type"
                label-for="account_type"
              >
                <v-select
                  id="account_type"
                    placeholder="Select Account Type"
                  v-model="data.account_type"
                  :options="accountTypeOptions"
                  :reduce="val => val.value"
                  @input="showHideCompany"
                />
              </b-form-group>
            </b-col>
             <b-col
              v-if="showIsCompany"
              md="6"
            >
              <b-form-group
                label="Company Name"
                label-for="firm_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <b-form-input
                    id="firm_name"
                    v-model="data.firm_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="showIsCompany"
              md="6"
            >
              <b-form-group
                label="Company Registration No"
                label-for="firm_reg_no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Firm registartion number+"
                  rules="required"
                >
                  <b-form-input
                    id="firm_reg_no"
                    v-model="data.firm_reg_no"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
              <b-col md="6">
              <b-form-group
                label="Passport/ID Number"
                label-for="passport_no"
              >
                <b-form-input
                  id="linked-in"
                  v-model="data.passport_no"
                />
              </b-form-group>
            </b-col>
           
          </b-row>
           <!--  <b-col md="6">
              <b-form-group
                label="Notification Balance"
                label-for="min_balance"
              >
                <b-form-input
                  id="min_balance"
                  v-model="data.min_balance"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Notification Email"
                label-for="notification_email"
              >
                <b-form-input
                  id="notification_email"
                  v-model="data.notification_email"
                />
              </b-form-group>
            </b-col> -->

          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</div>
</div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend, validate} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueCountdown from '@chenfengyuan/vue-countdown'
import SecureLS from 'secure-ls'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import {
  BButton,
  BRow,
  BForm,
  BInputGroup,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email, numeric } from '@validations'
import CommonService from '../../services/common.service'
import UserService from '../../services/user.service'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BButton,
    BInputGroupAppend,
    FormWizard,
    BSpinner,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BInputGroup,
    BFormDatepicker,
    BFormInvalidFeedback,
     countdown: VueCountdown,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stateOptions: [],
      rankOptions: [],
      planOptions: [],
      countryOptions: [],
      required,
      otp: '',
      email: '',
      stopTrying: 1,
      waitingTime: 0,
      showTimer: 0,
      errorShow: false,
      message: '',
      validMessge: '',
       loading: false,
      validtrueMessge: '',
      showIsCompany: false,
      email,
      gender: '',
      genderOptions: [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' },
      ],
      religion:'',
      religionOptions: [
        { value: 'Islam', label: 'Islam' },
        { value: 'Buddhism/Taoism', label: 'Buddhism/Taoism' },
        { value: 'Hinduism', label: 'Hinduism' },
        { value: 'Others', label: 'Others' },
      ],
      accountTypeOptions: [
        { value: 'individual', label: 'Individual Account' },
        { value: 'company', label: 'Company Account' },
      ],
      data: {},
    }
  },
  created() {
    this.getUserInfo()
    this.getState()
    this.getCountry()
  },
   mounted() {
    const validateOtp = value => UserService.vaildEmailOTP({ email: this.email, code: value }).then(
      response => {
        if (response.success) {
          this.otp = value
          this.success = true
          this.showTimer = false
          this.stopTrying = false
          this.validMessge = '',
          this.validtrueMessge = response.message
        } else {
          this.validMessge = response.message
        }

        return {
          valid: response.success,
          data: {
            message: response.message,
          },
        }
      },
      error => {
        this.success = false
        this.validMessge = error.response.data.message
        this.stopTrying = error.response.data.data.stopTrying
        return {
          valid: false,
          data: {
            message: error.response.data.message,
          },
        }
      },
    )
    extend('validOtp', {
      validate: validateOtp,
      message: '',
    }, {
      immediate: false,
    })
  },

  methods: {

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.emailRule.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationOtherInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.otherInfoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    handleCountdownEnd() {
      this.stopTrying = 1
      this.showTimer = 0
    },
    getUserInfo(value) {
      UserService.getUserInfo().then(
        response => {
          this.data = response
          this.email = response.email
          // if (response.state != '' && response.state != 0) {
          //   this.data.state = { label: response.state, value: response.state }
          // } else {
          //   this.data.state = { value: 0, label: ' Select state' }
          // }

          // if (response.account_type != null) {
          //   this.data.account_type = { label: response.account_type, value: response.account_type }
          // } else {
          //   this.data.account_type = { value: 0, label: ' Select Account Type' }
          // }
          // if (response.religion != null) {
          //   this.religion = { label: response.religion, value: response.religion }
          // } else {
          //   this.religion = { value: '', label: ' SelectReligion' }
          // }

          // if (response.gender != null) {
          //   this.gender = { label: response.gender, value: response.gender }
          // }
          if (this.data.account_type.value == 'company') {
            this.showIsCompany = true
          } else {
            this.showIsCompany = false
          }
          this.$refs.active.activateAll()
        },
        error => {},
      )
    },
    refreshInfo() {
      this.getUserInfo('ref')
    },
      async  requetOTP() {
      const isValid = await this.$refs.emailprovider.validate()
      if (isValid.valid) {
        this.loading = true
        UserService.requestEmailOTP({ email: this.email }).then(
          response => {
            this.error = false
            this.stopTrying = response.stopTrying
            this.showTimer = 1
            this.waitingTime = response.waitingTime * 1000
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OTP',
                text: response.message,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          },
          error => {
            this.loading = false
            const self = this
            if (error.response.errors != undefined) {
              Object.entries(error.response.data.errors).map((value, key) => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    text: value[1][0],
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              })
            } else {
              this.errorShow = true
              this.message = error.response.data.message
            }
          },
        )
      }
    },
    getState() {
      CommonService.getState().then(
        response => {
          const self = this
          response.state.map((value, key) => {
            self.stateOptions.push(
              { value: value.state, label: value.state },
            )
          })
        },
        error => {},
      )
    },
    getCountry() {
      CommonService.getCoutry().then(
        response => {
          const self = this
          response.country.map((value, key) => {
            self.countryOptions.push(
              { value: value.country, label: value.country },
            )
          })
        },
        error => {},
      )
    },
    updateInfo() {
      this.$store.dispatch('updateProfile', this.data).then(
        response => {
          this.refreshInfo()
          if (response.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update',
                text: 'Profile Update Successfully',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            window.location = '/dashboard'
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: response.error,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        },
        error => {
          const self = this
          if (error.response.data.errors != undefined) {
            Object.entries(error.response.data.errors).map((value, key) => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: value[1][0],
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: error.response.data.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        },
      )
    },
    showHideCompany() {
      if (this.data.account_type.value == 'company') {
        this.showIsCompany = true
      } else {
        this.showIsCompany = false
      }
    },
  },
};

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';

</style>
